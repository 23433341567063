
.provider{
    position: relative;
    top: 0px;
}
.wagering-table{
        height: auto;
        width: 100%;
    }

.wageering-template-checkbox{
    float: left;
    cursor: pointer;
}
.small-size-error{
    font-size: 13px;
}
.wagering-template-save-button{
    position: relative;
    top: 32px;
}

.wagering-table tr td input {
    margin-left: 12px;
}
/*
* = Buttons
*/

.btn { 
    &.btn-main {
        background-color: $mainColor !important;
        border-color: $mainColor !important;
        color: $whiteColor !important;
        svg {
            fill: $whiteColor !important;
        }
        &.outline-main {
            background-color: transparent !important;
            border-color: $mainColor !important;
            color: $mainColor !important;
            svg {
                fill: $mainColor !important;
            }
        }
        &:hover,
        &:active,
        &:focus,
        &.active {
            background-color: $mainDarkColor !important;
            border-color: $mainDarkColor !important;
            color: $whiteColor !important;
            svg {
                fill: $whiteColor !important;
            }
        }
    }
    &.btn-primary {
        background-color: $primaryColor !important;
        border-color: $primaryColor !important;
        color: $whiteColor !important;
        svg {
            fill: $whiteColor !important;
        }
        &.outline-primary {
            background-color: transparent !important;
            border-color: $primaryColor !important;
            color: $primaryColor !important;
            svg {
                fill: $primaryColor !important;
            }
        }
        &:hover,
        &:active,
        &:focus,
        &.active {
            background-color: $primaryDarkColor !important;
            border-color: $primaryDarkColor !important;
            color: $whiteColor !important;
            svg {
                fill: $whiteColor !important;
            }
        }
    }
    &.btn-secondary {
        background-color: $secondaryColor !important;
        border-color: $secondaryColor !important;
        color: $whiteColor !important;
        svg {
            fill: $whiteColor !important;
        }
        &.outline-secondary {
            background-color: transparent !important;
            border-color: $secondaryColor !important;
            color: $secondaryColor !important;
            svg {
                fill: $secondaryColor !important;
            }
        }
        &:hover,
        &:active,
        &:focus,
        &.active {
            background-color: $secondaryDarkColor !important;
            border-color: $secondaryDarkColor !important;
            color: $whiteColor !important;
            svg {
                fill: $whiteColor !important;
            }
        }
    }
    &.btn-info {
        background-color: $infoColor !important;
        border-color: $infoColor !important;
        color: $whiteColor !important;
        svg {
            fill: $whiteColor !important;
        }
        &.outline-info {
            background-color: transparent !important;
            border-color: $infoColor !important;
            color: $infoColor !important;
            svg {
                fill: $infoColor !important;
            }
        }
        &:hover,
        &:active,
        &:focus,
        &.active {
            background-color: $infoDarkColor !important;
            border-color: $infoDarkColor !important;
            color: $whiteColor !important;
            svg {
                fill: $whiteColor !important;
            }
        }
    }
    &.btn-success {
        background-color: $successColor !important;
        border-color: $successColor !important;
        color: $whiteColor !important;
        svg {
            fill: $whiteColor !important;
        }
        &.outline-success {
            background-color: transparent !important;
            border-color: $successColor !important;
            color: $successColor !important;
            svg {
                fill: $successColor !important;
            }
        }
        &:hover,
        &:active,
        &:focus,
        &.active {
            background-color: $successDarkColor !important;
            border-color: $successDarkColor !important;
            color: $whiteColor !important;
            svg {
                fill: $whiteColor !important;
            }
        }
    }
    &.btn-warning {
        background-color: $warningColor !important;
        border-color: $warningColor !important;
        color: $whiteColor !important;
        svg {
            fill: $whiteColor !important;
        }
        &.outline-warning {
            background-color: transparent !important;
            border-color: $warningColor !important;
            color: $warningColor !important;
            svg {
                fill: $warningColor !important;
            }
        }
        &:hover,
        &:active,
        &:focus,
        &.active {
            background-color: $warningDarkColor !important;
            border-color: $warningDarkColor !important;
            color: $whiteColor !important;
            svg {
                fill: $whiteColor !important;
            }
        }
    }
    &.btn-danger {
        background-color: $dangerColor !important;
        border-color: $dangerColor !important;
        color: $whiteColor !important;
        svg {
            fill: $whiteColor !important;
        }
        &.outline-danger {
            background-color: transparent !important;
            border-color: $dangerColor !important;
            color: $dangerColor !important;
            svg {
                fill: $dangerColor !important;
            }
        }
        &:hover,
        &:active,
        &:focus,
        &.active {
            background-color: $dangerDarkColor !important;
            border-color: $dangerDarkColor !important;
            color: $whiteColor !important;
            svg {
                fill: $whiteColor !important;
            }
        }
    }
    &.btn-text {
        background-color: transparent !important;
        border-color: transparent !important;
        color: $darkColor !important;
        svg {
            fill: $darkColor !important;
        }
        &:hover,
        &:active,
        &:focus,
        &.active {
            background-color: $primaryColor !important;
            border-color: $primaryColor !important;
        }
    }
}

.btn-check:focus + .btn, .btn:focus {
    outline: 0;
    // box-shadow: none !important;
}




.btn {
    &.btn-circle {
        border-radius: $circle-radius;
    }

    &.btn-md {
        padding: 0.65rem 1.25rem;
    }

    &.btn-xs {
        padding  : 0.175rem 0.45rem;
        font-size: 0.7rem;
    }
}

.btn-icon-only {
    width  : 2.575rem;
    height : 2.575rem;
    padding: 0;
    @include display-flex();
    @include justify-content(center);
    @include align-items(center);

    &.btn-xs {
        width : 1.7rem;
        height: 1.7rem;
    }

    &.btn-sm {
        width : 2rem;
        height: 2rem;
    }
}


//
// Block button
//

.btn-block {
    display: block;
    width  : 100%;

    // Vertically space out multiple block buttons
    +.btn-block {
        margin-top: $btn-block-spacing-y;
    }
}
.copy-code-button {
    position: absolute;
    top: 22px;
    right: 20px;
    background-color: transparent;
    color: $gray-600;
    border: 0;
    box-shadow: none;

    &:hover {
        background-color: $primary;
        color: $white;
        border: 0;
        box-shadow: none;
    }
}

.copy-code-text {
    position: absolute;
    top: 24px;
    right: 90px;
}

.actionButton {
    margin: 0 10px 10px 10px;
}

.circle-button {
    border-radius: 100%;
    height: 16px;
}

.tenant-circle-button {
    border-radius: 100%;
    height: 12px;
}

.active-button{
    border: 1px solid #2ba32b;
    background-color: #2ba32b;
}

.inactive-button{
    border: 1px solid red;
    background-color: red;
}
.btn-group > .btn:not(:last-child):not(.dropdown-toggle), .btn-group > .btn-group:not(:last-child) > .btn {
    border-radius: 0.5rem !important;
}

.btn-outline-primary {
    border-color: transparent;
}

.btn-group > .btn:nth-child(n+3), .btn-group > :not(.btn-check) + .btn, .btn-group > .btn-group:not(:first-child) > .btn {
    border-radius: 0.5rem !important;
}
.btns-group .btn {
    padding: 0.3rem 0.6rem;;
}

.btns-group .btn svg {
    color: $blue;
}
.btn-outline-primary:hover {
    background-color: $white !important;
}
.btn-light {
    background-color: $primaryColor;
    border: 0;
}
.btn-light:hover {
    background-color: none !important;
}
.btn-light svg {
    color: $white;
}
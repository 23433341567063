
.upload-card {
  display: grid;
  grid-template-rows: auto 1fr auto;
}
.input-file-label {
  padding: 1.5rem;
  display: flex;
  justify-content: center;
  align-items: center;
  border: dashed 2px #cfcfcf;
  border-radius: 0.5rem;
  position: relative;
  cursor: pointer;
  transition: ease 0.2s;
  height: inherit; 
  
  &:active {
    transform: scale(0.95);
  }
  &:hover {
    background-color: aliceblue;
  }
  &::after {
    content: attr(data-label);
    // position: absolute;
    // top: 50%;
    // left: 50%;
    // transform: translate(-50%, -50%);
    // width: max-content;
    font-size: 0.875rem;
    text-align: center;
    color: var(--bs-gray);
  }
  &[disabled=true], &[disabled] {
    opacity: 0.7;
    cursor: not-allowed;
  }
}
:root {
  --sidebar-width: #{$sideBarWidth};

  --blue-200: #49c7f4;
  --blue-300: #00A0DD;
  --blue-800: #003144;
  --blue-dark-800: #2B3674;
}


.w-content-fit,
.wc-fit {
    width: fit-content;
}
.mw-content-fit,
.mw-fit {
    min-width: fit-content;
}

.mh-100-dvh {
  min-height: 100vh;
  min-height: 100dvh;
}

.w-content-max,
.wc-max {
    width: max-content;
}

.w-content-min,
.wc-min {
    width: min-content;
}

.h-content-fit,
.hc-fit {
    height: fit-content;
}

.h-content-max,
.hc-max {
    height: max-content;
}

.h-content-min,
.hc-min {
    height: min-content;
}

.pointer,
.cursor-pointer {
    cursor: pointer;
}

.cursor-default {
    cursor: default;
}

.u,
.underline,
.u-hover:hover,
.underline-hover:hover {
    text-decoration: underline;
}

.line-normal,
.line-height-normal {
    line-height: normal;
}

.text-ellipsis {
  max-width: 100%;
	overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  line-height: normal;
}

.line-clamp,
.line-clamp-2,
.line-clamp-3 {
    overflow: hidden !important;
    display: -webkit-box !important;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    line-height: normal;
    line-break: auto;
}

.line-clamp-2 {
    -webkit-line-clamp: 2
}

.line-clamp-3 {
    -webkit-line-clamp: 3
}

.line-icon {
    display: inline-flex;
    white-space: pre-wrap;
    align-items: center;
}

.line-break {
    line-break: auto;
}

.word-break {
    word-break: break-all;
}

.flex-shrink-0 {
    flex-shrink: 0
}

.flex-shrink-1 {
    flex-shrink: 1
}

.white-space-wrap {
    white-space: pre-wrap;
}

a {
  text-decoration: none!important;
}

.color-blue-200 {
  color: var(--blue-200)!important;
}
.color-blue-300 {
  color: var(--blue-300)!important;
}
.color-blue-800 {
  color: var(--blue-800)!important;
}
.color-blue-dark-800 {
  color: var(--blue-dark-800)!important;
}

.bg-blue-200 {
  background-color: var(--blue-200)!important;
}
.bg-blue-300 {
  background-color: var(--blue-300)!important;
}
.bg-blue-800 {
  background-color: var(--blue-800)!important;
}
.bg-blue-dark-800 {
  background-color: var(--blue-dark-800)!important;
}

.bg-gray-200-hover:hover {
  transition: ease .2s;
  background: var(--bs-gray-200);
}

.max-width-5-rem {
  max-width: 5rem !important;
}
.min-width-10-rem {
  min-width: 10rem !important;
}

.bonus-banner-image {
  max-height: 20rem;
  width: 100%;
  height: auto;
  object-fit: cover;
  border-radius: 0.5rem;
}

.sub-category-icon {
  width: 2rem;
  height: 2rem;
  background: var(--blue-800);
  border-radius: 0.4rem;
  padding: 0.3rem;
  display: block;
  margin: auto;
}

.active.nav-item {
  .bg-cyan-purple-gradient {
    background: linear-gradient(19deg, #21D4FD 0%, #B721FF 100%) !important;
  }
}
.nav-item {
  .bg-cyan-purple-gradient {
    margin-bottom: 0.5rem;
    box-shadow: 0px 0px 0px 1px var(--blue-300);
  }
}

.fullscreen-video-modal {

  .modal-content {
    background-color: #000;
  }
  .modal-header {
    color: #fff;
    border-bottom: 1px solid #2a2a2a;
    .btn-close {
      backdrop-filter: contrast(0);
      margin: -0.5rem -0rem -.5rem auto;
    }
  }
}

.bold {
  font-weight: bold;
}
.uppercase {
  text-transform: uppercase;
}
.tenant-select{
    height: 30px;
    padding: 2px 25px 2px 20px;
    position: relative;
    bottom: 7px;
}
.tenant.main-nav{
height: 40px;
}
.tenant img{
    height: 50px;
    width: 50px;
    position: relative;
    bottom:7px
}
.h4-overview {
    margin: 10px 10px 0 10px;
    padding: 10px 0px;
    color: black;
}
.overview-subhead {
    color: #af00fd;
    border-bottom: 2px solid #edecec;
    font-size: 18px;
    padding-bottom: 2px;
    margin-left: 10px;
    margin-right: 10px;
}
.div-overview {
    margin: 5px 5px 15px 5px
}
.div-overview .actionButton {
    width: 42%;
}

.h4-hr {
    margin: 10px 0 15px 0;
}

.col-padding {
    padding-right: 0%;
}
.overview-leftlabel {
    color: #333;
    /* font-size: 0.875rem; */
    margin-bottom: 0.25rem;
}
/**
 * = Sections
 */

.section {
    position: relative;
    padding-top: $spacer * 3;
    padding-bottom: $spacer * 3;
}

.section-header {
    position: relative;
    padding-top: $spacer * 9;
    padding-bottom: $spacer * 3;
}

@include media-breakpoint-up(sm) {
    .section {
        position: relative;
        padding-top: $spacer * 5;
        padding-bottom: $spacer * 5;
    }

    .section-header {
        position: relative;
        padding-top: $spacer * 8;
        padding-bottom: $spacer * 8;

        &.section-sm {
            padding-top: $spacer * 4;
            padding-bottom: $spacer * 3;
        }
    }

    .section-xl {
        padding-top: $spacer * 8;
        padding-bottom: $spacer * 8;
    }

    .section-lg {
        padding-top: $spacer * 6;
        padding-bottom: $spacer * 6;
    }

    .section-sm {
        padding-top: $spacer * 3;
        padding-bottom: $spacer * 3;
    }
}

@include media-breakpoint-up(lg) {
    .section {
        position: relative;
        padding-top: $spacer * 6;
        padding-bottom: $spacer * 6;
    }

    .section-header {
        position: relative;
        padding-top: $spacer * 10;
        padding-bottom: $spacer * 10;

        &.section-sm {
            padding-top: $spacer * 4;
            padding-bottom: $spacer * 3;
        }
    }

    .section-xl {
        padding-top: $spacer * 10;
        padding-bottom: $spacer * 10;
    }

    .section-lg {
        padding-top: $spacer * 8;
        padding-bottom: $spacer * 8;
    }

    .section-sm {
        padding-top: $spacer * 4;
        padding-bottom: $spacer * 4;
    }
}

// Hero sections
.section-hero {
    @include media-breakpoint-up(md) {
        height: 100vh;
    }
}

//Section delimiter
.line-bottom {
    &::after {
        content: '';
        display: block;
        width: 80%;
        position: absolute;
        bottom: 0;
        left: 50%;
        margin-left: -40%;
        height: 1px;
        background: radial-gradient(ellipse at center, $light 0, rgba(255, 255, 255, 0) 80%);
    }
}

// Profile cover
.section-profile-cover {
    height: 580px;
    background-size: cover;
    background-position: center center;

    @include media-breakpoint-down(md) {
        height: 400px;
    }
}

.login-heading {
    color: #333;
    font-weight: bold;
}

label {
    color: $primaryColor;
}

.logoImage {
    position: absolute;
    left: 15px;
    top: 15px;
}

.leafImage {
    position: absolute;
    left: 0px;
    bottom: 0px;
}

.form-control {
    border: 0.0625rem solid #a3a4a7   !important;
    height: 50px;
}

// Components section
.components-section {
    >.form-control {
        +.form-control {
            margin-top: .5rem;
        }
    }

    >.nav+.nav,
    >.alert+.alert,
    >.navbar+.navbar,
    >.progress+.progress,
    >.progress+.btn,
    .badge,
    .btn {
        margin-top: .5rem;
        margin-bottom: .5rem;
    }

    .btn-group {
        margin-top: .5rem;
        margin-bottom: .5rem;

        .btn {
            margin: 0;
        }
    }

    .alert {
        margin: 0;

        +.alert {
            margin-top: 1.25rem;
        }
    }

    .badge {
        margin-right: .5rem;
    }

    .modal-footer {
        .btn {
            margin: 0;
        }
    }
}

h3,
h2,
h4,
h5,
h6 {
    color: $primaryColor;
}

.section-header-wrap {
    background-color: $lightColor !important;
    box-shadow: rgba(34, 51, 84, 0.1) 0px 2px 4px -3px, rgba(34, 51, 84, 0.05) 0px 5px 12px -4px;
    padding: 2rem;
    margin-bottom: 2rem;
    .title-text {
        color: $mainColor;
        font-size: 2rem;
        margin-bottom: 0;
        @include media-breakpoint-up(xs) {
          font-size: 1.5rem;
        }
    }
    .title-image {
      position: relative;
      width: 5rem;
      height: 5rem;
      border-radius: 50%;
      border: dashed 2px #cfcfcf;
      overflow: hidden;
      flex-shrink: 0;
      img {
        object-fit: cover;
        width: 100%;
        height: 100%;
        z-index: 1;
        position: relative;
      }

      &::before {
        content: "Image";
        position: absolute;
        text-align: center;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        color: #cfcfcf;
        font-size: 0.7rem;
      }
    }
}

.content-header-wrap {
    background-color: #d2ccdb !important;
    box-shadow: rgba(34, 51, 84, 0.1) 0px 2px 4px -3px, rgba(34, 51, 84, 0.05) 0px 5px 12px -4px;
    padding: 1.25rem;
    margin-bottom: 1.25rem;
    .title-text {
        color: $mainColor;
        font-size: 1.25rem;
        margin-bottom: 0;
    }
}

// Index Page
.presentation-box {
    position: relative;

    // phone
    .gadget {
        height: 500px;
        max-width: 100%;
    }

    .pricing-card,
    .dropdown-btn-img,
    .blog-card,
    .profile-card,
    .navbar-img,
    .modal-img,
    .social-btn-img {
        position: absolute;
        @include box-shadow($box-shadow);
        @include border-radius($border-radius);
    }

    .profile-card {
        left: 67%;
        top: 19%;
        height: 100px;
    }

    .navbar-img {
        left: 50%;
        top: 56%;
        height: 65px;
    }

    .modal-img {
        left: 38%;
        top: 80%;
        height: 100px;
    }

    .blog-card {
        left: 2%;
        top: 20%;
        height: 60px;
    }

    .pricing-card {
        left: 15%;
        top: 47%;
        height: 125px;
    }

    .social-btn-img {
        left: 51%;
        top: 78%;
        height: 25px;
    }

    .dropdown-btn-img {
        left: 25%;
        top: 10%;
        height: 25px;
    }

    // tablet
    @include media-breakpoint-up(md) {
        .gadget {
            height: 600px;
            max-width: 100%;
        }

        .pricing-card,
        .dropdown-btn-img,
        .blog-card,
        .profile-card,
        .navbar-img,
        .modal-img,
        .social-btn-img {
            position: absolute;
            @include box-shadow($box-shadow);
            @include border-radius($border-radius);
        }

        .profile-card {
            left: 65%;
            top: 12%;
            height: 150px;
        }

        .navbar-img {
            left: 51%;
            top: 55%;
            height: 80px;
        }

        .modal-img {
            left: 36%;
            top: 80%;
            height: 100px;
        }

        .blog-card {
            left: 7%;
            top: 20%;
            height: 80px;
        }

        .pricing-card {
            left: 18%;
            top: 40%;
            height: 150px;
        }

        .social-btn-img {
            left: 60%;
            top: 80%;
            height: 25px;
        }

        .dropdown-btn-img {
            left: 30%;
            top: 9%;
            height: 25px;
        }
    }

    // macbook
    @include media-breakpoint-up(lg) {
        width: 650px;

        .gadget {
            height: auto;
        }

        .pricing-card,
        .dropdown-btn-img,
        .blog-card,
        .profile-card,
        .navbar-img,
        .modal-img,
        .social-btn-img {
            position: absolute;
            @include box-shadow($box-shadow);
            @include border-radius($border-radius);
        }

        .profile-card {
            left: 75%;
            top: 3%;
            height: 200px;
        }

        .navbar-img {
            left: 55%;
            top: 65%;
            height: 120px;
        }

        .modal-img {
            left: 19%;
            top: 67%;
            height: 170px;
        }

        .blog-card {
            left: 7%;
            top: 3%;
            height: 100px;
        }

        .pricing-card {
            left: 0%;
            top: 30%;
            height: 165px;
        }

        .social-btn-img {
            left: 2%;
            top: 78%;
            height: 25px;
        }

        .dropdown-btn-img {
            left: 30%;
            top: -12%;
            height: 25px;
        }
    }
}

// Pages section
.card-box {
    @include perspective(900px);
    width: 250px;
    position: relative;

    .card-component {
        @include transform-style(preserve-3d);
        position: relative;
        height: 300px;

        @include media-breakpoint-up(lg) {
            height: 450px;
        }

        &.card-component-lg {
            height: auto;

            @include media-breakpoint-up(xs) {
                max-height: 800px;
            }

            @include media-breakpoint-up(md) {
                max-height: 410px;
            }
        }

        &.card-component-xs {
            height: auto;
            max-height: 230px;
        }

        .front {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            overflow: hidden;
            @include box-shadow($box-shadow-lg);
            @include border-radius($border-radius);
            @include backface-visibility(hidden);
            @include transform(rotateY(24deg));

            &:hover {
                cursor: pointer;
            }
        }
    }

    .page-card {
        @include box-shadow($box-shadow-lg);
        @include border-radius($border-radius);

        &:hover {
            cursor: pointer;
        }
    }

    @include media-breakpoint-down(md) {
        width: 200px;
    }

    @include media-breakpoint-up(lg) {
        width: 260px;
    }
}

.copy-docs {
    position: absolute;
    top: 5px;
    right: 5px;
    transition: $transition-base;

    &.copied {
        background: $success;
    }

    &:hover {
        cursor: pointer;
    }
}

.index-icon {
    position: absolute;
    font-size: 38px;
    color: #dcdcdc;
    transition: 0.3s all ease;
}

.index-icon-javascript {
    bottom: 80px;
    left: 40px;
}

.index-icon-javascript:hover {
    color: #f1da1c;
}

.index-icon-bootstrap {
    bottom: -150px;
    right: -7%;
}

.index-icon-bootstrap:hover {
    color: #553d7c;
}

.icon-sass:hover {
    color: #CD6799;
}

.index-icon-code {
    bottom: 180px;
    left: -80px;
}

.index-icon-code:hover {
    color: #ff7f66;
}

.index-icon-gulp:hover {
    color: #e34a4f;
}

.index-icon-gulp {
    left: 250px;
    bottom: -10px;
}

.index-icon-html5 {
    right: 30px;
    bottom: 150px;
}

.index-icon-html5:hover {
    color: #e54b20;
}

.index-icon-css3 {
    right: 40px;
    bottom: -20px;
}

.index-icon-css3:hover {
    color: #e54b20;
}

.index-icon-npm {
    right: 180px;
    bottom: 20px;
}

.index-icon-npm:hover {
    color: #cc3f3d;
}

.index-icon-fontawesome {
    right: 340px;
    bottom: -40px;
}

.index-icon-fontawesome:hover {
    color: #3499EF;
}

.index-icon-illustrations {
    left: 20px;
    bottom: -40px;
}

.index-icon-illustrations:hover {
    color: $secondary;
}

.FormsBg {
    background-color: $white;
    padding: 1rem 1.25rem;
    border-radius: 10px;
    box-shadow: rgba(159, 162, 191, 0.18) 0px 9px 16px, rgba(159, 162, 191, 0.32) 0px 2px 2px;
}
 .TableBg {
    background-color: $white;
    padding: 0px 0px 1.25rem 0;
    border-radius: 10px;
    box-shadow: rgba(159, 162, 191, 0.18) 0px 9px 16px, rgba(159, 162, 191, 0.32) 0px 2px 2px;
}

body {
    background-color: rgb(242, 245, 249) !important;
}

// .bs-tooltip-top .tooltip-arrow::before, .bs-tooltip-auto[data-popper-placement^=top] .tooltip-arrow::before {
//     top: -1px;
//     border-width: 0.4rem 0.4rem 0;
//     border-top-color: #fff;
// }
// .bs-tooltip-top > .tooltip-inner {
//     background-color: #fff;
//     color: #000;
//     border: 1px solid #062e56;
// }

.permission-card {
    box-shadow: rgba(159, 162, 191, 0.18) 0px 9px 16px, rgba(159, 162, 191, 0.32) 0px 2px 2px;
    .permission-wrap {
        &:not(:last-child) {
            border-bottom: 0.0625rem solid #a3a4a7;
            padding-bottom: 1.25rem;
            margin-bottom: 1.25rem;
        }
        .permission-name {
            color: $darkColor;
            font-size: 1rem;
            font-weight: 700;
            margin: 0.625rem 0;
        }
        .form-check {
            height: 40px;
            background-color: $lightColor;
            border: 0.0625rem solid #a3a4a7;
            border-radius: 5px;
            display: flex;
            align-items: center;
            padding-left: 34px;
            margin: 0.25rem 0;
            .form-check-input {
                width: 1.3rem;
                height: 1.3rem;
                margin-right: 10px;
                border: 1px solid #5c6168 !important;
                // background-color: #07003d;
            }
            .form-check-label {
                color: $darkColor;
            }
        }
    }
}
.sports-csv {
    margin-left: 10px;
}

.pagination {
    .form-label {
        color: $mainColor;
        font-size: 0.875rem;
    }
    .form-select {
        border: 0 !important;
        box-shadow: none !important;
        font-size: 0.875rem;
        margin-left: -0.75rem;
        &:focus {
            outline: 0;
            box-shadow: none;
        }
    }
    .pagination {
        .page-item {
            .page-link {
                border: 0 !important;
                box-shadow: none !important;
                font-size: 0.875rem;
                span {
                    font-size: 1.25rem;
                    line-height: 1.1;
                }
            }
            &.active {
                .page-link {
                    color: white;
                }
            }
        }
    }
}

.permissions-card {
    height: calc(100% - 0.75rem);
    border: 0;
    border-bottom: 0.313rem solid $secondaryLightColor;
    box-shadow: rgba(50, 50, 105, 0.15) 0px 2px 5px 0px, rgba(0, 0, 0, 0.05) 0px 1px 1px 0px !important;
    margin-top: 0.75rem;
    .card-header {
        background-color: transparent;
        border: 0;
        display: flex;
        align-items: flex-end;
        padding: 0 1rem;
        margin-top: -0.75rem;
        .icon {
            width: 2.75rem;
            height: 2.75rem;
            background-color: $primaryDarkColor;
            border-radius: 01.25rem 0.313rem 0.313rem 0.313rem;
            -webkit-border-radius: 01.25rem 0.313rem 0.313rem 0.313rem;
            -moz-border-radius: 01.25rem 0.313rem 0.313rem 0.313rem;
            -ms-border-radius: 01.25rem 0.313rem 0.313rem 0.313rem;
            -o-border-radius: 01.25rem 0.313rem 0.313rem 0.313rem;
            display: flex;
            justify-content: center;
            align-items: center;
            svg {
                fill: $whiteColor;
                color: $whiteColor;
                font-size: 1.25rem;
            }
        }
        .text {
            color: $mainColor;
            font-weight: 700;
            display: block;
            margin-left: 0.75rem;
            margin-bottom: 0.25rem;
        }
    }
    .list-group {
        padding: 0.75rem 0;
        .list-group-item {
            .check-img {
                width: 16px;
                max-width: 100%;
                height: auto;
            }
        }
    }
}

.FieldsName span {
    font-weight: bold;
}

.chart-card {
    .card-header {
        background-color: transparent;
        padding: 0.75rem 1rem;
        .title-text {
            color: #00133c;
            font-size: 16px;
            margin-bottom: 0;
        }
    }
    .card-body {
        padding: 1rem;
    }
}

.admin-left {
    background: #006D96;
}

.admin-right .btn.btn-primary {
    background-color: #49C7F4 !important;
    border-color: #49C7F4 !important;
}

.admin-right .btn.btn-primary:hover {
    background-color: #006D96 !important;
    border-color: #006D96 !important;
}

.admin-right .fwd-link {
    color: #49C7F4;
    cursor: pointer;
}

.admin-right .fwd-link:hover {
    color: #006D96;
}

@media (max-width: 767px) {
    .logoImage img {
        width: 120px;
    }
    .BGImage img {
        width: 200px;
        padding-top: 50px;
    }
    .leafImage {
        display: none;
    }
}
.comment-term-text {
    display: inline-block;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    width: 100%;
    cursor: pointer;
}
.review-desc {
    display: inline-block;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    width: 100%;
    cursor: pointer;
}

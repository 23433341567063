#cover-spin {
  position: fixed;
  margin-left: var(--sidebar-width);
  width: 100%;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  background-color: transparent;
  // z-index: 9999;

  &[data-center=true] {
    margin: 0;
    &::after {
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }
  }
  &[class] {
    margin-left: 0;
  }
}

@-webkit-keyframes spin {
  from {
    -webkit-transform: rotate(0deg);
  }

  to {
    -webkit-transform: rotate(360deg);
  }
}

@keyframes spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

#cover-spin::after {
  content: '';
  display: block;
  position: absolute;
  left: 38%;
  top: 38%;
  width: 40px;
  height: 40px;
  border-style: solid;
  border-color: black;
  border-top-color: transparent;
  border-width: 4px;
  border-radius: 50%;
  -webkit-animation: spin .8s linear infinite;
  animation: spin .8s linear infinite;

}
#cover-spin[class]::after {
  left: 48%;
  // top: 48%;
}

#small-spinner {
margin-top: 7px;
margin-left: 10px;
}

#small-spinner::after {
  content: '';
  display: block;
  width: 15px;
  height: 15px;
  border-style: solid;
  border-color: black;
  border-top-color: transparent;
  border-width: 3px;
  border-radius: 50%;
  -webkit-animation: spin .8s linear infinite;
  animation: spin .8s linear infinite;
}

#btn-danger {
  margin-top: 2px;
  margin-left: 10px;
  display: flex;
  justify-content: center;
}
  
#inline-spinner::after {
  content: '';
  display: block;
  width: 20px;
  height: 20px;
  border-style: solid;
  border-color: rgb(136, 136, 136);
  border-top-color: transparent;
  border-width: 3px;
  border-radius: 50%;
  -webkit-animation: spin .8s linear infinite;
  animation: spin .8s linear infinite;
}

.btn-danger #inline-spinner,
.btn-primary #inline-spinner {
  &::after {
    border-color: #FFFFFF;
    border-top-color: transparent;
  }
}
.btn-outline-success #inline-spinner {
  &::after {
    border-color: #198754;
    border-top-color: transparent;
  }
}
.btn-outline-success:hover #inline-spinner {
  &::after {
    border-color: #FFFFFF;
    border-top-color: transparent;
  }
}


.btn[data-loading=true] {
  position: relative;
  span {
    opacity: 0;
  }
  #inline-spinner {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
}


#table-spinner {
  width: 100%;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
}
    
#table-spinner::after {
  content: '';
  display: block;
  position: absolute;
  left: 50%;
  top: 60%;
  width: 40px;
  height: 40px;
  border-style: solid;
  border-color: black;
  border-top-color: transparent;
  border-width: 4px;
  border-radius: 50%;
  -webkit-animation: spin .8s linear infinite;
  animation: spin .8s linear infinite;
}

[data-type=loading] {
  position: relative;
  #cover-spin {
    width: max-content;
    height: max-content;
    margin: 0!important;
    position: absolute;
    top: 50%; left: 50%;
    transform: translate(-50%, -50%);

    &::after {
      position: static;
      top: 50%;
      left: 50%;
    }
  }
}
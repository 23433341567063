.highcharts-legend.highcharts-no-tooltip{
    display: none !important;
}
.highcharts-credits{
    display: none !important;
}
.fixTableHead {
    overflow-y: auto;
    height: 110px;
  }
  .fixTableHead thead th {
    position: sticky;
    top: 0;
  }
  table {
    border-collapse: collapse;        
    width: 100%;
  }


.demographicCharts{
width: 400px;
margin-left: 10px;
height: 100px;
/* background-color: black; */
position: absolute;
bottom: 0;
}
.demographicCharts span{
  position: absolute;
  left: 25px;
  font-weight: 600;
  font-size: 12px;
}

.demographicCharts span.first{
top:4px
}

.demographicCharts span.second{
  top:24px
}

.demographicCharts span.third{
  top:44px
}

.demographicCharts .d-charts{
width: 15px;
height: 15px;
border-radius: 5px;
margin-top: 5px;
}

.d-charts.first{
  background-color:#b4ecff;
}
.d-charts.second{
  background-color: rgb(0, 217, 255);
}
.d-charts.third{
  background-color: #00a2ff;
}

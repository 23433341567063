.update-button-icon {
  &[data-loading=true] svg {
    animation: spin .8s linear infinite;
  }
}

@keyframes spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

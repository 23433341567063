.tool-card{
    border:1px solid #ccc;
    border-radius:10px;
    padding: 20px;
    text-align: center;
    h4{
        color: #000;
        font-weight: bold;
        text-align: center;
    }
    .tool-value{
        padding: 12px 20px;
        text-align: center;
        min-width: 100px;
    }
    .custom-select-wrap{
        max-width: 200px;
        margin-left: auto;
    }
}
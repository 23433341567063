
table.table {
    tbody tr td span {
        position: relative;
    }
    tbody tr td [data-notify=true]::before {
        content: '';
        position: absolute;
        width: 0.4rem;
        height: 0.4rem;
        border-radius: 50%;
        top: 50%;
        left: -0.5rem;
        color: #fff;
        font-size: 0.7rem;
        transform: translate(-50%, -50%);
        background: rgb(252, 62, 62);
    }

    &.table-sm>:not(caption)>*>* {
        padding: 0.25rem 1.25rem;
    }
}

.tab-pane {
    min-height: 7rem;
} 

.btn {
  &.btn-sm {
    min-height: 2.18rem;
  }
}

.badge, [data-badge] {
  border-radius: 29rem;
  --bs-badge-padding-x: 0.65em;
  --bs-badge-padding-y: 0.35em;
  --bs-badge-font-size: 0.75em;
  --bs-badge-font-weight: 500;
  --bs-badge-color: #fff;
  --bs-badge-background: #333;
  
  display: inline-block;
  padding: var(--bs-badge-padding-y) var(--bs-badge-padding-x);
  font-size: var(--bs-badge-font-size);
  font-weight: var(--bs-badge-font-weight);
  line-height: 1;
  color: var(--bs-badge-color);
  background-color: var(--bs-badge-background);
  text-align: center;
  white-space: nowrap;
  vertical-align: baseline;
  display: flex;
  justify-content: center;
  align-items: center;
  height: fit-content;

  &.size-sm {
    --bs-badge-padding-y: 0.25em;
    --bs-badge-font-size: 0.55em;
  }


  &.info, &[data-badge="info"], &[data-badge="visible"], &[data-badge="yes"], &[data-badge="casino-win"], &[data-badge="CORRECAO"] {
    --bs-badge-color: #fff;
    --bs-badge-background: var(--bs-cyan);
  }
  &.primary, &[data-badge="primary"], &[data-badge="requested"], &[data-badge="claiming"] {
    --bs-badge-color: #fff;
    --bs-badge-background: var(--bs-primary);
  }
  &.danger, &[data-badge="danger"], &[data-badge="rejected"], &[data-badge="cancelled"], &[data-badge="expired"], &[data-badge="failed"], &[data-badge="NOK"] {
    --bs-badge-color: #fff;
    --bs-badge-background: var(--bs-red);
  }
  &.success, &[data-badge="success"], &[data-badge="approved"], &[data-badge="active"], &[data-badge="claimed"], &[data-badge="casino-bet"], &[data-badge="OK"], &[data-badge="DIARIO"], &[data-badge="ORIGINAL"] {
    --bs-badge-color: #fff;
    --bs-badge-background: var(--bs-teal);
  }
  &.warning, &[data-badge="warning"], &[data-badge="pending"], &[data-badge="MENSAL"], &[data-badge="RETIFICACAO"] {
    --bs-badge-color: #735b37;
    --bs-badge-background: var(--bs-yellow);
  }
  &.disabled, &[data-badge="not-visible"], &[data-badge="disabled"], &[data-badge="inactive"], &[data-badge="no"] {
    --bs-badge-color: #fff;
    --bs-badge-background: #bec0c2;
  }
}

.form-check.form-check-reverse.form-switch {
  flex-direction: row-reverse;
  display: flex;
  width: 100%;
  justify-content: space-between;
  padding-right: 2.5em;
  padding-left: 0;
  align-items: center;
  flex-wrap: wrap;

  .form-check-input {
    margin-left: 0;
    margin-right: -2.5em;
  }

  label {
    margin: 0;
    width: 100%;
  }
  
  input, label {
    cursor: pointer;
  }

  .valid-feedback {
    display: block;
    margin: 0;
    color: var(--bs-gray-600);
  }
}

.object-fit-contain {
  object-fit: contain;
}

.object-fit-cover {
  object-fit: cover;
}

.text-feedback { 
  color: var(--bs-gray-600)!important;
}

.placeholder-glow .placeholder {
  animation: placeholder-glow 2s ease-in-out infinite;
}

.placeholder {
  background-color: currentcolor;
  cursor: wait;
  display: inline-block;
  min-height: 1em;
  opacity: .5;
  vertical-align: middle;
  border-radius: 0.4rem;
}

@keyframes placeholder-glow {
  50% {
      opacity: .2
  }
}

.custom-container {
  width: 100%;
  font-size: 14px;
  line-height: 130%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  position: relative;
  .date {
    width: auto;
    position: relative;
  }
  .custom-input-field {
    width: 100%;
    min-height: 30px;
    color: #66799e;
    padding-left: 15px;
    border: 0.0625rem solid #d1d7e0;
  }
  input {
    text-align: center;
    cursor: pointer;
  }
  .calendar-view {
    border-radius: 10px;
    box-shadow: rgba(159, 162, 191, 0.18) 0px 9px 16px, rgba(159, 162, 191, 0.32) 0px 2px 2px;
    overflow: hidden;
    z-index: 9999;
    position: absolute;
    top: 35px;

    &[data-placement=right] {
      right: 0;
    }
    &[data-placement=left] {
      left: 0;
    }
}
}


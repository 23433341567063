/**
 * = Input groups
 */

.input-group {
	border-radius: $input-border-radius;
	transition: $transition-base;
	position: relative;

	.input-addon {
		background-color: transparent !important;
		border: 0 !important;
		border-radius: 0 !important;
		z-index: 4;
		position: absolute;
		top: 50%;
		transform: translateY(-50%);
		-webkit-transform: translateY(-50%);
		-moz-transform: translateY(-50%);
		-ms-transform: translateY(-50%);
		-o-transform: translateY(-50%);

		&.icon-first {
			left: 1px;
		}

		&.icon-last {
			right: 1px;
		}
	}

	&:has(.icon-first) .form-control {
		padding-left: calc(3rem + 1px);
		border-radius: 0.5rem !important;
	}

	&:has(.icon-last) .form-control {
		padding-right: calc(3rem + 1px);
		border-radius: 0.5rem !important;
	}

	.form-control {
		box-shadow: none;

	}
  
  .btn.btn-input-group {
    color: #212529;
    background-color: #fff;
    border: 0.0625rem solid #a3a4a7 !important;

    &:hover {
      background-color: #efeded;
    }
  }

  .icon-size-input-group {
    width: 16px;
    height: 16px;
  }
}

.input-group-text {
	font-size: $font-size-sm;
	transition: $input-transition;
	border-right: 0;
	height: 35px;
}

.form-check-input:checked {
	background-color: $primaryColor !important;
	border-color: $primaryColor !important;
}
.form-check-input:focus {
	border-color: $primaryColor !important;
    outline: 0;
    box-shadow: none;
}

.input-group-prepend {
	margin-right: -2px;
}

// .focus class is applied dinamycally from theme.js

.focused {

	.input-group-text {
		color: $input-group-addon-focus-color;
		background-color: $input-group-addon-focus-bg;
	}

	.input-group-shadow {
		box-shadow: $shadow-input-focus;
	}
}

select,
.select__control {
	border: 0.0625rem solid #a3a4a7 !important;
	box-shadow: none !important;
	border-radius: 0.3rem !important;
	min-height: 35px;
	line-height: 1 !important;
	font-size: 0.9rem !important;
}

.form-label {
	color: #333 !important;
	font-size: 0.875rem;
	margin-bottom: 0.25rem;
}

label {
	color: #333 !important;
	font-size: 0.875rem;
	margin-bottom: 0.25rem;
}

.DefaultRole {
	border: 0.0625rem solid #a3a4a7 !important;
	height: 35px;
	line-height: 35px;
	border-radius: 0.3rem;
	padding-left: 10px;
	margin-bottom: 0;
}

.form-control, .input-group .btn, .select__control {
	height: 35px ;
	min-height: 35px !important;
	border-radius: 0.3rem;
}

::-webkit-input-placeholder { /* Edge */
	font-size: 0.9rem !important;
  }
  
  :-ms-input-placeholder { /* Internet Explorer 10-11 */
	font-size: 0.9rem !important;
  }
  
  ::placeholder {
	font-size: 0.9rem !important;
  }

  .css-1s2u09g-control {
	  min-height: auto !important;
  }

  .form-text img {
	border: 2px solid #cbbdbd;
    padding: 5px;
    border-radius: 5px;
  }

  .FileWidth input {
	  width: 200px;
  }

  .form-control[type=file] {
	  line-height: 16px;
  }
/**
 * = Sidebars
 */

#doc-index:not(.collapse.show), .doc-sidebar {
    display: none;
}

@include media-breakpoint-up(lg){
    #doc-index{
        display: block;
    }
    
    .doc-sidebar {
        display: block;
        height: calc(100vh - 2rem);
        overflow-y: scroll;
        position: -webkit-sticky;
        position: sticky;
        top: 2rem;

        .nav-link[data-toggle="collapse"] > .icon {
            transform: rotateZ(-90deg);
            position: relative;
            right: .25rem;
        }

        .nav{
            .nav-item{
                font-size:$font-size-sm;
            }
        }
    }
}  

.upgrade-to-pro {
    position: fixed;
    left: 15px;
    bottom: 15px;
    width: 230px;
    z-index: 999;
}
.sidebar-btn-wrap {
    width: calc(var(--sidebar-width) / 1.15);
    background-color: $mainColor;
    border-top: 1px solid rgba(255, 255, 255, 0.25);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 10;
    padding: 15px 0;
    position: fixed;
    bottom: 0;
    left: 17px;
}

@media (max-width:767px){
    .sidebar-btn-wrap {
        width: 100%;
        position: relative;
        bottom: 0;
        left: 0;
    }
}

.sidebar .sidebar-heading-label {
    color: #bdb5b5 !important;
    font-size: 1rem;
    text-transform: uppercase;
    display: block;
    margin: 1.5rem 0 0.25rem;
}

.sidebar .nav-item .nav-link {
    border: 0 !important;
    color: #b1a5d4;
}

.sidebar .nav-item .nav-link:hover, .sidebar .nav-item.active > .nav-link {
    color: $white !important;
    background-color: $primaryColor !important;
    border: 0 !important;
}

.sidebar-icon svg {
    color: $whiteColor;
}

.sidebar {
    background-color: #180443!important;
}
/**
 * = Paginations
 */
 .circle-pagination{
    .page-link,
    span{
        @include display-flex();
        align-items: center;
        justify-content: center;
        width: 34px;
        height: 34px;
        padding: 0;
        @include border-radius($circle-radius);
    }
}
.page-item.active .page-link {
    z-index: 3;
    color: #333;
    background-color: $primaryColor !important;
    border-color: $blue;
    border-radius: 0.3rem;
}

ul.pagination {
    margin-bottom: 0;
}
.page-item.disabled .page-link {
    color: $default;
}